/*
bleu #1d6e96


rouge #a54d4a
*/

body, hr, option, select{
  color: #1d6e96;
}

h1{
  margin-top: 1em;
}

.customer-data p{
  margin:0;
  font-size: smaller;
}

.container{
  padding:2em;
}

form{
  padding-top: 5em;
}

a em, a:visited em, em{
  color: #1d6e96;
  font-weight: 700;
}

a{
  text-decoration: underline;
}

a:hover em, a:hover{
  color:#fe0000;
}

.consignes p{
  font-weight: 800;
}

input[type="submit"]{
  background-color: #309235;
  border:#309235;
  font-size:1.5em;
  margin-top:2em;
}

.logo{
  width:20em;
}

small{
  display:block;
}

span.error{
  color:#fe0000;
  font-weight: 700;
}

.notification{
  width:100%;
  padding:1em;
  border-radius:1em;
  margin-top: 2em;
  margin-bottom: 2em;
  color:white;
  font-weight: 700;
}

.notification.error{
  background-color: #fe0000;
}

.notification.submit-success{
  background-color: green;
}

.notification.done{
  background-color: green;
}

.notification.submit-error{
  background-color: #fe0000;
}

.center{
  text-align: center;
}

hr{
  border-top: 0.1rem solid lightgrey;
}

footer{
  padding-top: 5em;
  font-size: small;
}

footer strong{
  color: #1d6e96;
}

strong{
  color:#a54d4a;
}

span{
  padding-left: 1em;
}

a, a:visited{
  color: #1d6e96;
}

a:hover{
  color: #a54d4a;
}